import { css } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import Lightbox from './Lightbox'
import ShareButton from './ShareButton'

const GalleryLightbox = ({ open, image, title, onClose, ...props }) => {
  const contentStyle = css`
    width: auto;
    max-width: 100%;
    padding-bottom: var(--gutter-sm);
    box-sizing: border-box;
    /* max-height: calc(
      100 * var(--vh, 1vh) - var(--gutter-md) - var(--gutter-mlg)
    );
    overflow: hidden; */
    display: flex;
    flex-direction: column;
    ${mq().s} {
      padding: 0.25rem;
    }
  `
  const imageWrapStyle = css`
    padding-top: calc(var(--gutter-sm) + 1rem);
    flex: 1;
    height: 100%;
    position: relative;
    ${mq().s} {
      margin-top: 3.5rem;
      padding-top: 0;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: max(
        calc(
          100 * var(--vh, 1vh) - 7rem - 2 * var(--gutter-sm) -
            var(--gutter-md) - var(--gutter-mlg)
        ),
        37.5vw
      );
    }
  `
  const titleStyle = css`
    position: relative;
    font-size: var(--fs-18);
    font-weight: 400;
    margin: 1.5rem 0 0;
    height: 2.5rem;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-x: clip;
  `
  const shareButtonStyle = css`
    right: 0;
    bottom: 0;
  `
  if (open) {
    return (
      <Lightbox onClose={onClose} contentCss={contentStyle} {...props}>
        <div css={imageWrapStyle}>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt || title}
            title={title}
            backgroundColor={colors.ecruShade2}
          />
          <ShareButton css={shareButtonStyle} />
        </div>
        <h2 css={titleStyle}>{title}</h2>
      </Lightbox>
    )
  }
  return <></>
}

export default GalleryLightbox
