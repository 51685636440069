import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useMemo, useState } from 'react'

import { absoluteFill, mq, scrim } from '../theme/mixins'
import { colors } from '../theme/variables'
import { toSlug } from '../utils/helpers'
import GalleryLightbox from './GalleryLightbox'

const GalleryItem = ({ data, category, ...props }) => {
  const { galleryPage } = useStaticQuery(graphql`
    query {
      galleryPage: datoCmsGalleryPage {
        slug
      }
    }
  `)

  const [open, setOpen] = useState(false)
  const handleOpen = e => {
    e.preventDefault()
    setOpen(true)
  }

  const format = array => array.map(x => x.name).join(' + ')
  const title = useMemo(
    () =>
      `${data.cabinetString || format(data.cabinet)} • ${
        data.woodTypeString || format(data.woodType)
      } • ${data.finishString || format(data.finish)}`,
    [data]
  )

  const slug = useMemo(
    () =>
      `/${galleryPage.slug}/${toSlug(category)}/${toSlug(title)}-${
        data.id
      }`,
    [galleryPage, category, data, title]
  )

  const galleryItemStyle = css`
    position: relative;
    overflow: hidden;
    ${mq().s} {
      div > & {
        scroll-snap-align: center;
      }
    }
  `
  const galleryItemImageStyle = css`
    height: 100%;
    width: 100%;
    display: flex;
    img {
      min-width: 100%;
      min-height: 100%;
    }
    transition: transform 450ms ease-out;
    will-change: transform;
    @media (hover: hover) {
      a:hover > & {
        transition: transform 250ms ease;
        transform: scale3d(1.05, 1.05, 1);
      }
    }
  `
  const textStyle = css`
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    color: white;
    padding: 5rem 1rem 0.75rem;
    h3 {
      position: relative;
      text-align: center;
      font-family: var(--slab-serif);
      font-size: var(--fs-15);
      margin: 0;
      padding-bottom: 0.25em;
      text-shadow: 0 0 0.5em black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:before {
      content: '';
      ${absoluteFill}
      ${scrim('black', 0.5, 'to top')}
    }
  `
  return (
    <a
      href={slug + '/'}
      onClick={e => handleOpen(e)}
      css={galleryItemStyle}
      {...props}
    >
      <div css={galleryItemImageStyle}>
        {data.image && (
          <GatsbyImage
            image={data.image.thumbnail}
            title={title}
            alt={data.image.alt || title}
            backgroundColor={colors.ecruShade2}
          />
        )}
      </div>
      <div css={textStyle}>
        <h3>{title}</h3>
      </div>

      <GalleryLightbox
        image={data.image}
        title={title}
        open={open}
        data={{
          slug,
        }}
        onClose={() => setOpen(false)}
      />
    </a>
  )
}

export default GalleryItem
