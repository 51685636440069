import { css } from '@emotion/react'
import { rgba } from 'polished'
import React from 'react'

import { allCaps, baseGrid, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import GalleryItem from './GalleryItem'
import HorizontalSlider from './HorizontalSlider'

const GalleryGroup = ({ gallery, ...props }) => {
  const galleryStyle = css`
    ${baseGrid}
    margin: var(--gutter-lg) 0;
  `
  const headingStyle = css`
    grid-column: 2 / -2;
    border-top: 1px solid ${rgba(colors.grayLight, 0.5)};
    margin-bottom: 2rem;
    h2 {
      font-family: var(--sans-serif);
      font-size: var(--fs-15);
      ${allCaps}
      font-weight: 500;
      background-color: white;
      width: fit-content;
      padding: 0.5em 0.8em;
      margin: 0;
    }
  `
  const sliderStyle = css`
    grid-column: 1 / -1;
    width: 100vw;
  `
  const sliderScrollAreaStyle = css`
    scroll-padding-left: var(--margin-outer);
    ${mq().s} {
      scroll-padding-right: var(--margin-outer);
    }
  `
  const scrollWidthStyle = css`
    margin: 0 var(--margin-outer);
  `
  const sliderContentStyle = css`
    --number-columns: 4;
    --column-size: calc(
      (
          100vw - (2 * var(--margin-outer)) -
            (calc(var(--number-columns) - 1) * var(--gutter-sm))
        ) / var(--number-columns)
    );
    display: grid;
    grid-template-columns: repeat(
      ${gallery.images.length},
      var(--column-size)
    );
    grid-gap: var(--gutter-sm);
    padding: 0 var(--margin-outer);
    ${mq().ml} {
      --number-columns: 3;
    }
    ${mq().ms} {
      --number-columns: 2;
    }
    ${mq().s} {
      --number-columns: 1;
      --column-size: calc(
        var(--column-width) * 11 + var(--gutter-md) * 10
      );
    }
  `

  return (
    <section css={galleryStyle} {...props}>
      <div css={headingStyle}>
        <h2>{gallery.name}</h2>
      </div>
      <HorizontalSlider
        css={sliderStyle}
        contentCss={sliderContentStyle}
        scrollAreaCss={sliderScrollAreaStyle}
        scrollWidthCss={scrollWidthStyle}
      >
        {gallery.images.map((item, i) => (
          <GalleryItem data={item} category={gallery.name} key={i} />
        ))}
      </HorizontalSlider>
    </section>
  )
}

export default GalleryGroup
