import { css } from '@emotion/react'
import throttle from 'lodash/throttle'
import { rgba } from 'polished'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import smoothscroll from 'smoothscroll-polyfill'

import { colors } from '../theme/variables'

const HorizontalSlider = ({
  children,
  scrollWidthCss,
  scrollAreaCss,
  contentCss,
  ...props
}) => {
  const contentRef = useRef(null)
  const sliderRef = useRef(null)
  const scrollWidthRef = useRef(null)
  const [scrollPos, setScrollPos] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)

  useEffect(() => {
    sliderRef.current?.scrollTo(0, 0)
  }, [])

  const setWidths = useCallback(() => {
    if (
      sliderRef.current &&
      contentRef.current &&
      scrollWidthRef.current
    ) {
      setContainerWidth(
        Math.ceil(sliderRef.current.getBoundingClientRect().width)
      )
      setContentWidth(
        Math.ceil(contentRef.current.getBoundingClientRect().width)
      )
      setScrollWidth(
        Math.ceil(scrollWidthRef.current.getBoundingClientRect().width)
      )
    }
  }, [sliderRef, contentRef, scrollWidthRef])
  useEffect(() => {
    setWidths()
  }, [setWidths])

  if (typeof window !== 'undefined') {
    smoothscroll.polyfill()
  }

  const handleSetWidths = throttle(setWidths, 100)

  useEffect(() => {
    window.addEventListener('resize', handleSetWidths)
    return () => {
      window.removeEventListener('resize', handleSetWidths)
    }
  }, [handleSetWidths])

  const scrollEffect = () => {
    setScrollPos(
      contentRef.current.getBoundingClientRect().x -
        sliderRef.current.getBoundingClientRect().x
    )
  }
  const handleScroll = throttle(scrollEffect, 50)
  useEffect(() => {
    const slider = sliderRef.current
    slider.addEventListener('scroll', handleScroll)
    return () => {
      slider.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const handleScrollBack = () => {
    sliderRef.current.scrollBy({
      top: 0,
      left: -scrollWidth,
      behavior: 'smooth',
    })
  }
  const handleScrollForward = () => {
    sliderRef.current.scrollBy({
      top: 0,
      left: scrollWidth,
      behavior: 'smooth',
    })
  }

  const outerStyle = css`
    position: relative;
    overflow: hidden;
  `

  const sliderStyle = css`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    > div {
      position: relative;
      display: flex;
      overflow-x: auto;
      overflow-y: visible;
      width: 100%;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: ${sliderRef.current ? 'x mandatory' : 'unset'};
      // Hide scrollbar
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  `
  const contentStyle = css`
    position: relative;
    min-height: min-content;
    display: flex;
    box-sizing: content-box;
    > * {
      scroll-snap-align: start;
    }
  `
  const navStyle = css`
    display: contents;
    .scroll-button {
      border: none;
      cursor: pointer;
      background-color: transparent;
      position: absolute;
      z-index: 3;
      top: 50%;
      display: flex;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      background-color: ${rgba(colors.grayMid, 0.33)};
      width: max(
        calc(2 * var(--margin-outer) - var(--gutter-sm)),
        5rem
      );
      height: max(
        calc(2 * var(--margin-outer) - var(--gutter-sm)),
        5rem
      );
      border-radius: 50%;
      align-items: center;
      transition: background-color 300ms ease,
        transform 400ms cubic-bezier(0.33, 3, 0.25, 0.5);
      --translateXY: translate(-50%, -50%);
      transform: var(--translateXY);
      @media (hover: hover) {
        &:hover {
          background-color: ${rgba(colors.greenDark, 0.875)};
          transform: var(--translateXY) scale3d(1.125, 1.125, 1);
        }
      }
      &:active {
        transform: var(--translateXY) scale3d(1.075, 1.075, 1);
      }
      &.disabled {
        svg polyline {
          stroke: rgba(255, 255, 255, 0.25);
        }
        background-color: ${rgba(colors.grayMid, 0.05)};
        cursor: default;
        pointer-events: none;
      }
      &.scroll-back {
        justify-content: flex-end;
        left: 0;
        svg {
          transform: scaleX(-1);
          right: 17.5%;
        }
      }
      &.scroll-forward {
        --translateXY: translate(50%, -50%);
        right: 0;
        svg {
          left: 17.5%;
        }
      }
      svg {
        position: relative;
        width: auto;
        height: 37.5%;
        polyline {
          fill: transparent;
          stroke-width: 2;
          stroke: white;
        }
      }
    }
  `

  return (
    <div css={outerStyle} {...props}>
      <nav css={navStyle}>
        {containerWidth < contentWidth && (
          <>
            <button
              className={`scroll-button scroll-back ${
                scrollPos >= -5 ? 'disabled' : ''
              }`}
              onClick={handleScrollBack}
              onKeyPress={handleScrollBack}
              aria-label="scroll back"
            >
              <svg
                width="24px"
                height="48px"
                viewBox="0 0 24 48"
                vectorEffect="non-scaling-stroke"
              >
                <polyline
                  points="1 45.5 22.5 24 1 2.5"
                  vectorEffect="non-scaling-stroke"
                />
              </svg>
            </button>
            <button
              className={`scroll-button scroll-forward ${
                containerWidth - scrollPos >= contentWidth - 5
                  ? 'disabled'
                  : ''
              }`}
              onClick={handleScrollForward}
              onKeyPress={handleScrollForward}
              aria-label="scroll forward"
            >
              <svg width="24px" height="48px" viewBox="0 0 24 48">
                <polyline
                  points="1 45.5 22.5 24 1 2.5"
                  vectorEffect="non-scaling-stroke"
                />
              </svg>
            </button>
          </>
        )}
      </nav>
      <div css={scrollWidthCss} ref={scrollWidthRef} />
      <div css={sliderStyle}>
        <div css={scrollAreaCss} ref={sliderRef}>
          <div css={[contentStyle, contentCss]} ref={contentRef}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HorizontalSlider
