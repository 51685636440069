import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import GalleryGroup from '../components/GalleryGroup'
import Layout from '../components/Layout'
import PageIntroTextbox from '../components/PageIntroTextbox'
import Seo from '../components/Seo'
import { baseGrid } from '../theme/mixins'
import { colors } from '../theme/variables'

const GalleryPage = () => {
  const { page, galleries } = useStaticQuery(graphql`
    query {
      page: datoCmsGalleryPage {
        pageHeading
        pageBodyNode {
          childMarkdownRemark {
            html
          }
        }
        seo {
          title
          description
        }
      }
      galleries: allDatoCmsGallery(
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          name
          images {
            id: originalId
            image {
              gatsbyImageData(imgixParams: { q: 75 }, width: 1620)
              thumbnail: gatsbyImageData(
                imgixParams: { q: 60, ar: "1:1", fit: "crop" }
                width: 480
              )
              alt
            }
            cabinetString
            woodTypeString
            finishString
            cabinet {
              name
              doorStyle {
                name
              }
            }
            woodType {
              name
            }
            finish {
              ... on DatoCmsWoodStain {
                name
              }
              ... on DatoCmsWoodPaint {
                name
              }
            }
          }
        }
      }
    }
  `)
  const mainStyle = css`
    ${baseGrid}
    grid-template-rows: auto var(--gutter-mlg) auto;
    &:before {
      content: '';
      grid-column: 1 / -1;
      grid-row: 2 / span all;
      height: 100%;
      background-color: ${colors.ecruShade1};
    }
  `
  const textboxStyle = css`
    grid-row: 1 / 3;
    margin-bottom: 0;
  `
  const allGalleriesStyle = css`
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    padding-bottom: var(--gutter-sm);
  `
  return (
    <Layout mainCss={mainStyle}>
      <Seo
        title={page.seo?.title || page.pageHeading}
        description={page.seo?.description}
      />
      <PageIntroTextbox
        heading={page.pageHeading}
        bodyNode={page.pageBodyNode}
        css={textboxStyle}
      />
      <section css={allGalleriesStyle}>
        {galleries.nodes.map((gallery, i) => (
          <GalleryGroup key={i} gallery={gallery} />
        ))}
      </section>
    </Layout>
  )
}

export default GalleryPage
